<template>
  <div>
    <com-mon>
      <el-card body-style="padding:15px">
        <div class="grayFont">商家可查看客户咨询和员工接待的数据和接待人员的明细，还可按时间和客服帐号进行筛选。
          <el-link href="https://jusnn6k8al.feishu.cn/docs/doccnkedNFisS64SjRM4EB2h2qg?from=from_copylink"
           target="_blank" type="primary" :underline="false">查看数据统计说明</el-link>
        </div>
      </el-card>
      <el-card style="margin-top:10px">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="接待数据" name="1"></el-tab-pane>
          <el-tab-pane label="销售数据" name="2"></el-tab-pane>
        </el-tabs>
        <div>
          <div class = "flexRow">
            <div class = "keyFont">客服帐号：</div>
            <el-select v-model="serviceId" placeholder="请选择接入客服帐号" style="width:300px" @change="getHeaderImg" v-loading="serviceLoading">
              <div slot="prefix" class = "flexRow" style="height:100%" v-if="serviceId"> 
                <img :src="imgUrl+serviceMsg.HeadUrl" alt="" style="width:24px;height:24px" />
              </div>
              <el-option v-for="v in serviceList" :key="v.QyWeixinKfAccountId" :value="v.QyWeixinKfAccountId" :label="v.Name">
                <div class = "flexRow" style="height:100%">
                  <img :src="imgUrl+v.HeadUrl" alt="" style="width:24px;height:24px" />
                  <span style="margin-left:3px">{{v.Name}}</span>
                </div>
              </el-option>
              <div class = "flexRow" slot="empty" style="justify-content: center;padding:10px">
                <div class="grayFont" style="color:#909399">还未添加客服帐号，</div>
                <el-button type="text" @click="$router.push({path:'/customerServe/WxCustomerServe/serviceaccount'})">前往添加</el-button>
              </div>
            </el-select>
          </div>
          <div v-if="activeName==1">
            <div class = "headerFont" style="margin:20px 0px">数据概况</div>
            <div class = "flexRow">
              <el-radio-group v-model="dataRadioType" @change="changeRadioTabs">
                <el-radio-button :label="1">客户咨询</el-radio-button>
                <el-radio-button :label="2">客服接待</el-radio-button>
                <el-radio-button :label="3">智能回复</el-radio-button>
              </el-radio-group>
              <div class = "flexRow" style="margin-left:30px">
                <div class = "keyFont">统计时间：</div>
                <el-date-picker v-model="dataTime" type="daterange" range-separator="至" @change="(e)=>{changeDataTime(e,1)}" :clearable="false"
                 :picker-options="pickerOptions" value-format="yyyy-MM-dd" format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" />
              </div>
            </div>
            <div v-loading="blueBoxLoading">
              <div class = "flexRow" style="margin:20px 0px 10px 0px;flex-wrap:wrap" v-if="dataRadioType==1">
                <div class = "blueBox">
                  <span class = "topFont">{{consultMsg.ConsultSessionCount?consultMsg.ConsultSessionCount:'--'}}</span>
                  <span class = "buttomFont">咨询会话数</span>
                </div>
                <div class = "blueBox">
                  <span class = "topFont">{{consultMsg.ConsultCustomerCount?consultMsg.ConsultCustomerCount:'--'}}</span>
                  <span class = "buttomFont">咨询客户数</span>
                </div>
                <div class = "blueBox">
                  <span class = "topFont">{{consultMsg.ConsultMessageCount?consultMsg.ConsultMessageCount:'--'}}</span>
                  <span class = "buttomFont">咨询消息总数</span>
                </div>
              </div>
              <div class = "flexRow" style="margin:20px 0px 10px 0px;flex-wrap:wrap" v-if="dataRadioType==2">
                <div class = "blueBox">
                  <span class = "topFont">{{consultMsg.ManualAccessCount?consultMsg.ManualAccessCount:'--'}}</span>
                  <span class = "buttomFont">接入人工会话数</span>
                </div>
                <div class = "blueBox">
                  <span class = "topFont">{{consultMsg.ManualReplyRate?consultMsg.ManualReplyRate+'%':'--'}}</span>
                  <span class = "buttomFont">人工回复率</span>
                </div>
                <div class = "blueBox">
                  <span class = "topFont">{{consultMsg.AvgFirstRespond?(consultMsg.AvgFirstRespond<1?'<1分钟':consultMsg.AvgFirstRespond+'分钟'):'--'}}</span>
                  <span class = "buttomFont">平均首次响应时长</span>
                </div>
                <div class = "blueBox">
                  <span class = "topFont">{{consultMsg.Evaluate1Rate?consultMsg.Evaluate1Rate+'%':'--'}}</span>
                  <span class = "buttomFont">"满意"评价占比</span>
                </div>
                <div class = "blueBox">
                  <span class = "topFont">{{consultMsg.Evaluate2Rate?consultMsg.Evaluate2Rate+'%':'--'}}</span>
                  <span class = "buttomFont">"一般"评价占比</span>
                </div>
                <div class = "blueBox">
                  <span class = "topFont">{{consultMsg.Evaluate3Rate?consultMsg.Evaluate3Rate+'%':'--'}}</span>
                  <span class = "buttomFont">"不满意"评价占比</span>
                </div>
              </div>
              <div class = "flexRow" style="margin:20px 0px 10px 0px;flex-wrap:wrap" v-if="dataRadioType==3">
                <div class = "blueBox">
                  <span class = "topFont">{{consultMsg.SmartReplySessionCount?consultMsg.SmartReplySessionCount:'--'}}</span>
                  <span class = "buttomFont">智能回复会话数</span>
                </div>
                <div class = "blueBox">
                  <span class = "topFont">{{consultMsg.TranToManualRate?consultMsg.TranToManualRate+'%':'--'}}</span>
                  <span class = "buttomFont">转人工率</span>
                </div>
                <div class = "blueBox">
                  <span class = "topFont">{{consultMsg.ConsultHitRate?consultMsg.ConsultHitRate+'%':'--'}}</span>
                  <span class = "buttomFont">知识命中率</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeName==2">
            <div class = "headerFont" style="margin:20px 0px">客服销售数据明细</div>
            <div class = "flexRow">
              <div class = "keyFont">统计时间：</div>
              <el-date-picker v-model="salesDataTime" type="daterange" range-separator="至" @change="(e)=>{changeDataTime(e,3)}" :clearable="false"
                :picker-options="pickerOptions" value-format="yyyy-MM-dd" format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" />
            </div>
            <div style="margin-top:15px">
              <el-table :data="salesTableList" v-loading="salesTableLoading">
                <el-table-column prop="" label="接待人员" key="sales1">
                  <template slot-scope="scope">
                    <div>{{scope.row.MallEmployeeName}}</div>
                    <div>{{scope.row.Phone}}</div>
                  </template>
                </el-table-column>
                <el-table-column prop="" label="销售人数（人）" key="sales2">
                  <template slot-scope="scope">
                    {{scope.row.PayMemberCount?scope.row.PayMemberCount:'--'}}
                  </template>
                </el-table-column>
                <el-table-column prop="" label="销售额（元）" key="sales3">
                  <template slot-scope="scope">
                    {{scope.row.OrderPayMoney?scope.row.OrderPayMoney:'--'}}
                  </template>
                </el-table-column>
                <el-table-column prop="" label="销售额占比" key="sales4">
                  <template slot-scope="scope">
                    {{scope.row.OrderPayMoneyRate?scope.row.OrderPayMoneyRate+'%':'--'}}
                  </template>
                </el-table-column>
                <el-table-column prop="" label="客单价（元）" key="sales5">
                  <template slot-scope="scope">
                    {{scope.row.UnitCustomPayMoney?scope.row.UnitCustomPayMoney:'--'}}
                  </template>
                </el-table-column>
                <el-table-column prop="" label="退款额（元）" key="sales6">
                  <template slot-scope="scope">
                    {{scope.row.RealRefundMoney?scope.row.RealRefundMoney:'--'}}
                  </template>
                </el-table-column>
              </el-table>
              <div style="margin-top:10px;text-align:right" v-if="total2">
                <el-pagination
                  @size-change="handleSizeChange2"
                  @current-change="handleCurrentChange2"
                  :current-page="currentPage2"
                  :page-sizes="[10, 20, 30, 40]"
                  :page-size="sizepage2"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="total2">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </el-card>

      <el-card style="margin-top:10px" v-if="activeName==1">
        <div class = "headerFont">客服数据接待明细</div>
        <div class = "flexRow" style="margin:15px 0px">
          <div class = "keyFont">统计时间：</div>
          <el-date-picker v-model="receptionDataTime" type="daterange" range-separator="至" @change="(e)=>{changeDataTime(e,2)}" :clearable="false"
            :picker-options="pickerOptions" value-format="yyyy-MM-dd" format="yyyy-MM-dd" start-placeholder="开始日期" end-placeholder="结束日期" />
        </div>
        <el-table :data="receptionDataTableList" v-loading="receptionDataTableLoading">
          <el-table-column prop="Name" label="接待人员"  key="reception1">
            <template slot-scope="scope">
              <div>{{scope.row.Name}}</div>
              <div>{{scope.row.Phone}}</div>
            </template>
          </el-table-column>
          <el-table-column prop="ConsultSessionCount" label="咨询会话数" key="reception2">
            <template slot-scope="scope">
              {{scope.row.ConsultSessionCount?scope.row.ConsultSessionCount:'--'}}
            </template>
          </el-table-column>
          <el-table-column prop="ConsultCustomerCount" label="咨询客户数" key="reception3">
            <template slot-scope="scope">
              {{scope.row.ConsultCustomerCount?scope.row.ConsultCustomerCount:'--'}}
            </template>
          </el-table-column>
          <el-table-column prop="ConsultMessageCount" label="资讯消息总数" key="reception4">
            <template slot-scope="scope">
              {{scope.row.ConsultMessageCount?scope.row.ConsultMessageCount:'--'}}
            </template>
          </el-table-column>
          <el-table-column prop="ManualReplyRate" label="人工回复率" key="reception5">
            <template slot-scope="scope">
              {{scope.row.ManualReplyRate?scope.row.ManualReplyRate+'%':'--'}}
            </template>
          </el-table-column>
          <el-table-column prop="AvgFirstRespond" label="平均首次响应时长" key="reception6">
            <template slot-scope="scope">
              {{scope.row.AvgFirstRespond?(scope.row.AvgFirstRespond<1?'<1分钟':scope.row.AvgFirstRespond+'分钟'):'--'}}
            </template>
          </el-table-column>
          <el-table-column prop="Evaluate1Rate" label='"满意"评价占比' key="reception7">
            <template slot-scope="scope">
              {{scope.row.Evaluate1Rate?scope.row.Evaluate1Rate+'%':'--'}}
            </template>
          </el-table-column>
          <el-table-column prop="Evaluate2Rate" label='"一般"评价占比' key="reception8">
            <template slot-scope="scope">
              {{scope.row.Evaluate2Rate?scope.row.Evaluate2Rate+'%':'--'}}
            </template>
          </el-table-column>
          <el-table-column prop="Evaluate3Rate" label='"不满意"评价占比' key="reception9">
            <template slot-scope="scope">
              {{scope.row.Evaluate3Rate?scope.row.Evaluate3Rate+'%':'--'}}
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:10px;text-align:right" v-if="total">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="sizepage"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </el-card>

    </com-mon>
  </div>
</template>
<script>
import Commom from "@/views/customerServe/component/Common.vue"
import config from "@/config"
import{
  qyweixinkfstatisticscustomerconsult,
  qyweixinkfstatisticscustomerreception,
  qyweixinkfstatisticssmartreply,
  qyweixinkfstatisticsmanualreceptiondetail,
  qyweixinkfstatisticsindex,
  qyweixinkfstatisticsmanualsalesdetail
}from "@/api/sv3.0.0"
export default{
  components:{
   	'com-mon':Commom,
  },
  data(){
    return{
      imgUrl:config.IMG_BASE,
      activeName:'1',
      serviceId:null,
      serviceMsg:{},
      serviceList:[],
      serviceLoading:false,
      dataRadioType:1,
      dataTime:[],
      pickerOptions:{
        disabledDate:(v)=>{
          return v.getTime() > new Date().getTime()-1000*60*60*24
        }
      },
      blueBoxLoading:false,
      consultMsg:{},

      receptionDataTableList:[],
      receptionDataTableLoading:false,
      receptionDataTime:[],
      currentPage:1,
      sizepage:20,
      total:null,

      salesTableList:[],
      salesTableLoading:false,
      salesDataTime:[],
      currentPage2:1,
      sizepage2:20,
      total2:null,
    }
  },
  created () {
    this.getDefaultTime()
    this.getserviceList()
  },
  methods: {
    //客户咨询
    async getqyweixinkfstatisticscustomerconsult(){
      this.blueBoxLoading = true
      try{
        let data = {
          QyWeixinKfAccountId:this.serviceId,
          StartAt:this.dataTime[0],
          EndAt:this.dataTime[1]+' '+'23:59:59',
        }
        let res = await qyweixinkfstatisticscustomerconsult(data)
        if(res.IsSuccess){
          // console.log(res)
          this.consultMsg = res.Result
        }
      }finally{
        this.blueBoxLoading = false
      }
    },
    //客户接待
    async getqyweixinkfstatisticscustomerreception(){
      this.blueBoxLoading = true
      try{
        let data = {
          QyWeixinKfAccountId:this.serviceId,
          StartAt:this.dataTime[0],
          EndAt:this.dataTime[1]+' '+'23:59:59',
        }
        let res = await qyweixinkfstatisticscustomerreception(data)
        if(res.IsSuccess){
          // console.log(res)
          this.consultMsg = res.Result
        }
      }finally{
        this.blueBoxLoading = false
      }
    },
    //智能回复
    async getqyweixinkfstatisticssmartreply(){
      this.blueBoxLoading = true
      try{
        let data = {
          QyWeixinKfAccountId:this.serviceId,
          StartAt:this.dataTime[0],
          EndAt:this.dataTime[1]+' '+'23:59:59',
        }
        let res = await qyweixinkfstatisticssmartreply(data)
        if(res.IsSuccess){
          // console.log(res)
          this.consultMsg = res.Result
        }
      }finally{
        this.blueBoxLoading = false
      }
    },
    changeRadioTabs(e){
      if(e==1){
        this.getqyweixinkfstatisticscustomerconsult()
      }else if(e==2){
        this.getqyweixinkfstatisticscustomerreception()
      }else if(e==3){
        this.getqyweixinkfstatisticssmartreply()
      }
    },
    handleSizeChange2(e){
      this.currentPage2 = 1
      this.sizepage2 = e
      this.getsalesTableList()
    },
    handleCurrentChange2(e){
      this.currentPage2 = e
      this.getsalesTableList()
    },
    //销售数据明细列表
    async getsalesTableList(){
      this.salesTableLoading = true
      try{
        this.total2 = null
        this.salesTableList = []
        let data = {
          QyWeixinKfAccountId:this.serviceId,
          StartTime:this.salesDataTime[0],
          EndTime:this.salesDataTime[1]+' '+'23:59:59',
          Skip:(this.currentPage2-1)*this.sizepage2,
          Take:this.sizepage2
        }
        let res = await qyweixinkfstatisticsmanualsalesdetail(data)
        if(res.IsSuccess){
          this.salesTableList = res.Result.Results
          this.total2 = res.Result.Total
        }
      }finally{
        this.salesTableLoading = false
      }
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.sizepage = e
      this.getreceptionDataTableList()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.getreceptionDataTableList()
    },
    //数据接待明细列表
    async getreceptionDataTableList(){
      this.receptionDataTableLoading = true
      try{
        this.receptionDataTableList = []
          this.total = null
        let data = {
          QyWeixinKfAccountId:this.serviceId,
          StartAt:this.receptionDataTime[0],
          EndAt:this.receptionDataTime[1]+' '+'23:59:59',
          Skip:(this.currentPage-1)*this.sizepage,
          Take:this.sizepage
        }
        let res = await qyweixinkfstatisticsmanualreceptiondetail(data)
        if(res.IsSuccess){
          this.receptionDataTableList = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.receptionDataTableLoading = false
      }
    },
    getDefaultTime(){
      let date = new Date(new Date().getTime()-1000*60*60*24)
      let newdate = this.conversionTime(date)
      this.dataTime = [newdate.year+'-'+newdate.month+'-'+newdate.day,newdate.year+'-'+newdate.month+'-'+newdate.day]
      this.receptionDataTime = [newdate.year+'-'+newdate.month+'-'+newdate.day,newdate.year+'-'+newdate.month+'-'+newdate.day]
      this.salesDataTime = [newdate.year+'-'+newdate.month+'-'+newdate.day,newdate.year+'-'+newdate.month+'-'+newdate.day]
    },
    conversionTime(date){
      let year = date.getFullYear()
      let month = date.getMonth()+1>=10?date.getMonth()+1:'0'+(date.getMonth()+1)
      let day = date.getDate()>=10?date.getDate():'0'+date.getDate()
      return {
        year,month,day
      }
    },
    changeDataTime(e,type){
      if(!e||!e.length){
        return 
      }
      let [starttime,endtime] = e
      // console.log((new Date(endtime).getTime() - new Date(starttime).getTime())/1000/60/60/24)
      //最后传endtime时 取的是23:59:59的时间 而不是加一天 所以 >30
      let timepoor = (new Date(endtime).getTime() - new Date(starttime).getTime())/1000/60/60/24>30
      if(timepoor){
        this.$message.error('最多查询31天的数据')
        return 
      }
      this.currentPage = 1
      this.currentPage2 = 1
      this.sizepage = 20
      this.sizepage2 = 20
      this.total = null
      this.total2 = null
      if(type==1){
        this.changeRadioTabs(this.dataRadioType)
      }else if(type==2){
        this.getreceptionDataTableList()
      }else if(type==3){
        this.getsalesTableList()
      }
    },
    getHeaderImg(e){
      this.serviceMsg = this.serviceList.find((v)=>{
        return v.QyWeixinKfAccountId==e
      })
      this.handleClick({name:this.activeName})
    },
    handleClick(e){
      this.currentPage = 1
      this.currentPage2 = 1
      this.sizepage = 20
      this.sizepage2 = 20
      this.total = null
      this.total2 = null
      if(e.name==1){
        this.changeRadioTabs(this.dataRadioType)
        this.getreceptionDataTableList()
      }else{
        this.getsalesTableList()
      }
    },
    async getserviceList(){
      try{
        this.serviceLoading = true
        let res = await qyweixinkfstatisticsindex()
        if(res.IsSuccess){
          this.serviceList = res.Result.KfAccounts
          this.serviceMsg = res.Result.KfAccounts[0]
          this.serviceId = this.serviceMsg.QyWeixinKfAccountId
          // console.log(this.serviceList)
          this.getqyweixinkfstatisticscustomerconsult()
          this.getreceptionDataTableList()
        }
      }finally{
        this.serviceLoading = false
      }
    },
  }
}
</script>

<style lang="less" scoped>
.grayFont{
  font-size: 14px;
  color: #606266;
  line-height: 1.5;
}
.flexRow{
  display: flex;
  align-items: center;
  // justify-content: center;
}
.keyFont{
  font-size: 14px;
  color: #303133;
  // font-weight: bold;
  white-space: nowrap;
}
.headerFont{
  font-size: 16px;
  color: #303133;
  font-weight: bold;
}
.blueBox{
  width: 200px;
  height: 110px;
  background: #FBFDFF;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #409EEF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin:0px 20px 10px 0px;
  .topFont{
    font-size: 22px;
    color: #303133;
  }
  .buttomFont{
    font-size: 14px;
    color: #606266;
    margin-top: 15px;
  }
}
</style>
 
